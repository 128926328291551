import React, { useState } from "react";

const QandA = () => {
  const [isFirstDisplayed, setIsFirstDisplayed] = useState(false);
  const [isSecondDisplayed, setIsSecondDisplayed] = useState(false);
  const [isThirdDisplayed, setIsThirdDisplayed] = useState(false);
  const [isFourthDisplayed, setIsFourthDisplayed] = useState(false);
  const [isFifthDisplayed, setIsFifthDisplayed] = useState(false);
  const [isSixDisplayed, setIsSixDisplayed] = useState(false);
  const [isSeventhDisplayed, setIsSeventhDisplayed] = useState(false);
  const [isEightDisplayed, setIsEightDisplayed] = useState(false);
  const [isNinthDisplayed, setIsNinthDisplayed] = useState(false);

  return (
    <div className="contactUsHelper">
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsFirstDisplayed(!isFirstDisplayed)}>
          מה שונה בדאבל בהשוואה לאתרים אחרים?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isFirstDisplayed ? "transition" : "invisible-transition"}
        >
          בדאבל המשתמש לא הופך לכרטיס שמדפדים, ולא צריך להיכנס בשביל לחפש. דאבל
          עובד בשבילך ומחפש בשבילך. תוך שמירה על הפרטיות שלך - כאן הרי אי אפשר
          לדפדף .
          <br />
          בנוסף דאבל הוא כמו חבר, אז יש באתר גם טיפים לשלבים שונים בקשר, המלצות
          למקומות לדייטים, פעילויות, ועוד.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsSecondDisplayed(!isSecondDisplayed)}>
          אז.. האם יראו שנרשמתי לכאן ויראו את הפרטים שלי?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isSecondDisplayed ? "transition" : "invisible-transition"}
        >
          נרשמים פעם אחת למערכת והפרטים נשמרים במערכת במקום בטוח. כשדאבל מוצא
          התאמה - שני הצדדים רואים את הפרטים אחד של השניה, רק הם. ממש כמו שחבר
          מכיר לך ושולח לך אישית פרטים של התאמה אחת בשבילך ולא מפיצים את הפרטים
          שלך.
        </div>{" "}
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsThirdDisplayed(!isThirdDisplayed)}>
          {" "}
          לפי מה ההתאמה עובדת?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isThirdDisplayed ? "transition" : "invisible-transition"}
        >
          בתחילת יום שלישי ירוץ אלגוריתם שיבצע התאמות.
          <br />
          ההתאמות הן לפי פרמטרים רגילים כמו גיל, מרחק וכדומה, ובנוסף מתייחסים גם
          לאופי של הבן אדם. מי הוא ומי הוא מחפש. הכל לפי הגדרות שמגדירים למערכת,
          ישנה גמישות מסויימת, וגם היא לפי מה שהמשתמש מגדיר.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsNinthDisplayed(!isNinthDisplayed)}>
          {" "}
          האם שולחים רק לי את ההתאמה, ולמי שולחים קודם?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isNinthDisplayed ? "transition" : "invisible-transition"}
        >
          ההתאמות הן תמיד לשני הצדדים. והן רק בתנאי ששניכם סימנתם שמחפשים האחד
          את השניה, זאת אומרת: לא תקבלו (לדוגמה) הצעה בטווח גיל שלא מקובל עליכם.
          וגם כשתקבלו הצעה - שני הצדדים מקבלים אותה. במידה ושניכם אומרים כן -
          תקבלו על כך התראה במייל, וגם תראו זאת באתר.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsFourthDisplayed(!isFourthDisplayed)}>
          {" "}
          ואם דאבל לא ימצא לי?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isFourthDisplayed ? "transition" : "invisible-transition"}
        >
          אכן זה יכול לקרות, בסוף הכל מלמעלה. מה שכן - הפרטים שמורים במערכת
          וברגע שדאבל מוצא התאמה הוא שולח הודעה. ובינתיים הפרטים נשמרים, לא
          מופצים, ולא רואים שנרשמת.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsFifthDisplayed(!isFifthDisplayed)}>
          {" "}
          אני לא מאמינה באתרים, אין כמו היכרות אישית שמכירים לך
        </b>
        <br />
      </div>
      <div>
        <div
          className={isFifthDisplayed ? "transition" : "invisible-transition"}
        >
          אם את כאן, אז כנראה דרכים אחרות עוד לא עבדו. אז אולי אתר זו גם אופציה
          מעניינת?
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsSixDisplayed(!isSixDisplayed)}>
          {" "}
          האם יש דברים נוספים באתר?
        </b>
        <br />
      </div>
      <div>
        <div className={isSixDisplayed ? "transition" : "invisible-transition"}>
          באתר אפשר למצוא המלצות למקומות לדייטים, פעילויות לדייטים, טיפים,
          מאמרים, ועוד. בנוסף דאבל עוזר לך לבנות פרופיל אישי, יש טיפים, מחולל
          טקסט אישי. וכדומה.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsSeventhDisplayed(!isSeventhDisplayed)}>
          מה העלויות?
        </b>
        <br />
      </div>
      <div>
        <div
          className={isSeventhDisplayed ? "transition" : "invisible-transition"}
        >
          אחרי הרשמה יש חודש מנוי מתנה. לאחר מיכן - על מנת לראות פרטי התאמה צריך
          שאחד משני הצדדים יהיה מנוי לאתר. המחירים הם חברתיים ולכיסוי עלויות.
          (נכון לעכשיו המסלול הפופולרי הוא 75 שח לחצי שנה) וכן בשלב זה ניתן
          לרכוש מנוי עם נקודות - ללא עלות. מפורט באתר.
        </div>
      </div>
      <div className="bottom-line" />
      <div className="tellAboutUsSection right cursor">
        <b onClick={() => setIsEightDisplayed(!isEightDisplayed)}>
          למה בכלל הקמתם את דאבל? יש אפשרויות רבות אחרות
        </b>
        <br />
      </div>
      <div>
        <div
          className={isEightDisplayed ? "transition" : "invisible-transition"}
        >
          נכון, אבל האתר הזה שונה. הוא ממש כמו חבר שמכיר לך אישית. האתר הוקם
          מחוויה אישית שלי ושל חברי, במטרה לעשות טוב ולהקל על תקופת החיפוש.
          אנחנו קשובים ומשתדלים כל הזמן לשפר ולהיות כמה שיותר רגישים ומדויקים.
          <br />
          <b>ויש כבר חתונות ככה שבהחלט טוב שיש חבר כמו דאבל.</b>
        </div>
      </div>
      <div className="bottom-line" />
      ----------------------------------
      <br />
      האתר בפיתוח כל הזמן, ודאבל פתוח לשינויים והתאמות.
      <br />
      ומתוכננות הפתעות נוספות.
      <br />
      דאבל הוא תוכנה, וכמו תוכנה - לא מתעייף.
    </div>
  );
};

export default QandA;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Ellipsis } from "react-spinners-css";
import "../../App.css";
import "./DoubleMatch.css";
import "../../slider.css";
import HeaderBack from "./../../components/Header/HeaderBack";
import Footer from "../../components/Footer/Footer";
import ReactPlayer from "react-player";
import Popup from "reactjs-popup";
import PrevMatches from "../../components/PrevMatches/PrevMatches";
import TimerResponse from "../../components/TimerResponse/TimerResponse";

import axios from "axios";
import {
  getWordByGender,
  getHobby,
  getOtherChoice,
  getHared,
  getVip,
  getAlmostByVal,
  getGender,
} from "../../handlers/dictionaryHandler.js";
import FileManager from "../../utils/FileManager";
import DateCount from "../../components/Header/DateCount.js";

const cameraBtnClick = () => {
  if (document.getElementById("image1").style.display === "block") {
    document.getElementById("image1").style.display = "none";
  } else {
    document.getElementById("image1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const cameraBtnClick2 = () => {
  if (document.getElementById("image2").style.display === "block") {
    document.getElementById("image2").style.display = "none";
  } else {
    document.getElementById("image2").style.display = "block";
    document.getElementById("facebook2").style.display = "none";
    document.getElementById("record2").style.display = "none";
    document.getElementById("instegram2").style.display = "none";
    document.getElementById("linkedin2").style.display = "none";
  }
};

const facebookBtnClick = () => {
  if (document.getElementById("facebook1").style.display === "block") {
    document.getElementById("facebook1").style.display = "none";
  } else {
    document.getElementById("facebook1").style.display = "block";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const facebookBtnClick2 = () => {
  if (document.getElementById("facebook2").style.display === "block") {
    document.getElementById("facebook2").style.display = "none";
  } else {
    document.getElementById("facebook2").style.display = "block";
    document.getElementById("image2").style.display = "none";
    document.getElementById("record2").style.display = "none";
    document.getElementById("instegram2").style.display = "none";
    document.getElementById("linkedin2").style.display = "none";
  }
};

const instegramBtnClick = () => {
  if (document.getElementById("instegram1").style.display === "block") {
    document.getElementById("instegram1").style.display = "none";
  } else {
    document.getElementById("instegram1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const instegramBtnClick2 = () => {
  if (document.getElementById("instegram2").style.display === "block") {
    document.getElementById("instegram2").style.display = "none";
  } else {
    document.getElementById("instegram2").style.display = "block";
    document.getElementById("facebook2").style.display = "none";
    document.getElementById("image2").style.display = "none";
    document.getElementById("record2").style.display = "none";
    document.getElementById("linkedin2").style.display = "none";
  }
};

const linkedinBtnClick = () => {
  if (document.getElementById("linkedin1").style.display === "block") {
    document.getElementById("linkedin1").style.display = "none";
  } else {
    document.getElementById("linkedin1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
  }
};

const linkedinBtnClick2 = () => {
  if (document.getElementById("linkedin2").style.display === "block") {
    document.getElementById("linkedin2").style.display = "none";
  } else {
    document.getElementById("linkedin2").style.display = "block";
    document.getElementById("facebook2").style.display = "none";
    document.getElementById("image2").style.display = "none";
    document.getElementById("record2").style.display = "none";
    document.getElementById("instegram2").style.display = "none";
  }
};

const recordBtnClick = () => {
  if (document.getElementById("record1").style.display === "block") {
    document.getElementById("record1").style.display = "none";
  } else {
    document.getElementById("record1").style.display = "block";
    document.getElementById("image1").style.display = "none";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const recordBtnClick2 = () => {
  if (document.getElementById("record2").style.display === "block") {
    document.getElementById("record2").style.display = "none";
  } else {
    document.getElementById("record2").style.display = "block";
    document.getElementById("image2").style.display = "none";
    document.getElementById("facebook2").style.display = "none";
    document.getElementById("instegram2").style.display = "none";
    document.getElementById("linkedin2").style.display = "none";
  }
};

const DoubleMatch = () => {
  const [userName, setUserName] = useState("");
  const [startTimer1, setStartTimer1] = useState(false);
  const [startTimer2, setStartTimer2] = useState(false);
  const [vipExplain, setVipExplain] = useState(false);

  const [choice1, setChoice1] = useState("");
  const [choice1Saved, setChoice1Saved] = useState(false);

  const [messege, setMessege] = useState("");
  const [details1, setDetails1] = useState("");
  const [otherVip1, setOtherVip1] = useState("");
  const [otherName1, setOtherName1] = useState("");
  const [freeText1, setfreeText1] = useState("");
  const [cellFriend1, setCellFriend1] = useState("");
  const [image1a, setImage1a] = useState("");
  const [image2a, setImage2a] = useState("");
  const [image3a, setImage3a] = useState("");
  const [image4a, setImage4a] = useState("");
  const [image5a, setImage5a] = useState("");
  const [image1aBlobURL, setImage1aBlobURL] = useState("");
  const [image2aBlobURL, setImage2aBlobURL] = useState("");
  const [image3aBlobURL, setImage3aBlobURL] = useState("");
  const [image4aBlobURL, setImage4aBlobURL] = useState("");
  const [image5aBlobURL, setImage5aBlobURL] = useState("");
  const [facebook1, setFacebook1] = useState("");
  const [instegram1, setInstegram1] = useState("");
  const [linkedin1, setLinkedin1] = useState("");
  const [idMatch1, setIdMatch1] = useState("");
  const [cellular1, setCellular1] = useState("");
  const [religous1, setReligous1] = useState("");
  const [tall1, setTall1] = useState("");
  const [education1, setEducation1] = useState("");
  const [status1, setStatus1] = useState("");
  const [hobbies1, setHobbies1] = useState("");
  const [hobbieFree1, setHobbieFree1] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [origin1, setOrigin1] = useState("");
  const [otherResponse1, setOtherResponse1] = useState("");
  const [isEmailSent1, setIsEmailSent1] = useState("");
  const [record1a, setRecord1a] = useState("");
  const [myRecord1a, setMyRecord1a] = useState("");
  const [dateRecord1a, setDateRecord1a] = useState("");
  const [isRecord1aPlaying, setIsRecord1aPlaying] = useState(false);
  const [recordingBlobURL1a, setRecordingBlobURL1a] = useState("");
  const [record1b, setRecord1b] = useState("");
  const [myRecord1b, setMyRecord1b] = useState("");
  const [dateRecord1b, setDateRecord1b] = useState("");
  const [otherHadSee1, setOtherHadSee1] = useState("");
  const [msgForOtherSide1, setMsgForOtherSide1] = useState("");
  const [msgForOtherSideLength1, setMsgForOtherSideLength1] = useState("0");
  const [msgISent1, setMsgISent1] = useState("");
  const [msgIGot1, setMsgIGot1] = useState("");
  const [firstName1, setFirstName1] = useState("");
  const [renewDate1, setRenewDate1] = useState("");
  const [almost1, setAlmost1] = useState("");
  const [vipMatch1, setVipMatch1] = useState("");
  const handleText1 = (text) => {
    setMsgForOtherSide1(text);
    setMsgForOtherSideLength1(text.length);
  };

  const [choice2, setChoice2] = useState("");
  const [choice2Saved, setChoice2Saved] = useState(false);
  const [details2, setDetails2] = useState("");
  const [otherVip2, setOtherVip2] = useState("");
  const [otherName2, setOtherName2] = useState("");
  const [freeText2, setfreeText2] = useState("");
  const [cellFriend2, setCellFriend2] = useState("");
  const [image1b, setImage1b] = useState("");
  const [image2b, setImage2b] = useState("");
  const [image3b, setImage3b] = useState("");
  const [image4b, setImage4b] = useState("");
  const [image5b, setImage5b] = useState("");
  const [image1bBlobURL, setImage1bBlobURL] = useState("");
  const [image2bBlobURL, setImage2bBlobURL] = useState("");
  const [image3bBlobURL, setImage3bBlobURL] = useState("");
  const [image4bBlobURL, setImage4bBlobURL] = useState("");
  const [image5bBlobURL, setImage5bBlobURL] = useState("");

  const [facebook2, setFacebook2] = useState("");
  const [instegram2, setInstegram2] = useState("");
  const [linkedin2, setLinkedin2] = useState("");
  const [idMatch2, setIdMatch2] = useState("");
  const [cellular2, setCellular2] = useState("");
  const [religous2, setReligous2] = useState("");
  const [tall2, setTall2] = useState("");
  const [education2, setEducation2] = useState("");
  const [status2, setStatus2] = useState("");
  const [hobbies2, setHobbies2] = useState("");
  const [hobbieFree2, setHobbieFree2] = useState("");
  const [occupation2, setOccupation2] = useState("");
  const [origin2, setOrigin2] = useState("");
  const [otherResponse2, setOtherResponse2] = useState("");
  const [isEmailSent2, setIsEmailSent2] = useState("");
  const [record2a, setRecord2a] = useState("");
  const [myRecord2a, setMyRecord2a] = useState("");
  const [dateRecord2a, setDateRecord2a] = useState("");
  const [isRecord2aPlaying, setIsRecord2aPlaying] = useState(false);
  const [recordingBlobURL2a, setRecordingBlobURL2a] = useState("");
  const [record2b, setRecord2b] = useState("");
  const [myRecord2b, setMyRecord2b] = useState("");
  const [dateRecord2b, setDateRecord2b] = useState("");
  const [otherHadSee2, setOtherHadSee2] = useState("");
  const [msgForOtherSide2, setMsgForOtherSide2] = useState("");
  const [msgForOtherSideLength2, setMsgForOtherSideLength2] = useState("0");
  const [msgISent2, setMsgISent2] = useState("");
  const [msgIGot2, setMsgIGot2] = useState("");
  const [firstName2, setFirstName2] = useState("");
  const [renewDate2, setRenewDate2] = useState("");
  const [almost2, setAlmost2] = useState("");
  const [vipMatch2, setVipMatch2] = useState("");

  const handleText2 = (text) => {
    setMsgForOtherSide2(text);
    setMsgForOtherSideLength2(text.length);
  };

  const [doubleYes1, setDoubleYes1] = useState("");

  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessageFirstChoice, setErrorMessageFirstChoice] = useState("");

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [isMsgDisplayed, setIsMsgDisplayed] = useState(true);

  const [illswndwhatsupp, setIllswndwhatsupp] = useState(false);

  const getVipDescription = (match) => {};

  let history = useHistory();
  const location = useLocation();

  const handleChoice1 = (val) => {
    if (isEmailSent1 != "1" && vipMatch1 == "1") {
      setChoice1(val);
      // onSubmit(idMatch1, val);
    } else if (vipMatch1 == "0") {
      alertUserFirstChoice("ניתן לסמן תשובה כאשר אחד משני הצדדים מנוי");
    }
  };

  const handleChoice2 = (val) => {
    if (isEmailSent2 != "1" && vipMatch2 == "1") {
      setChoice2(val);
      //onSubmit(idMatch2, val);
    } else if (vipMatch2 == "0") {
      alertUser("ניתן לסמן תשובה כאשר אחד משני הצדדים מנוי");
    }
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const imgClick = (imgId) => {
    if (!location.pathname.includes("image")) {
      history.push("/doublematch/image" + imgId);
    } else {
      history.push("/doublematch");
    }
  };

  const alertUser2 = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage2(message);
  };

  const alertUserFirstChoice = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessageFirstChoice(message);
  };

  const infoBtnClick = () => {
    if (details1 !== "") {
      if (document.getElementById("match1").style.maxHeight === "2800px") {
        setStartTimer1(false);
        document.getElementById("match1").style.maxHeight = "0px";
      } else {
        document.getElementById("match1").style.maxHeight = "2800px";
        document.getElementById("match1").style.transition =
          "max-height 1s ease-in-out";
      }
      if (vipMatch1 == "1") {
        setStartTimer1(true);
        onSeeMatchDetails(idMatch1);
      }
    }
  };

  const infoBtnClick2 = () => {
    if (details2 !== "") {
      if (document.getElementById("match2").style.maxHeight === "2800px") {
        document.getElementById("match2").style.maxHeight = "0px";
        setStartTimer2(false);
      } else {
        document.getElementById("match2").style.maxHeight = "2800px";
        document.getElementById("match2").style.transition =
          "max-height 1s ease-in-out";
      }
      if (vipMatch2 == "1") {
        setStartTimer2(true);
        onSeeMatchDetails(idMatch2);
      }
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const onSeeMatchDetails = async (idMatch) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/matchHadSeen",
        {
          idMatch,
        },
        config
      );
    } catch (error) {
      //;
    }
  };

  const onCollectStars = async (kind) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PcollectStars",
        {
          kind,
        },
        config
      );
      if (response.data === true) {
        alertUser2(
          "נשמר בהצלחה, אספת 10 נקודות!! עם נקודת ניתן לקבל מנוי במתנה!"
        );
      } else {
        alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } catch (error) {
      console.log(error);
      alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/doubleMatchMessege",
        config
      );
      const userData = response.data;
      setMessege(userData.msg);
      localStorage.getItem("arriveFromPopup") == 1
        ? setIsMsgDisplayed(false)
        : setIsMsgDisplayed(true);
      if (localStorage.getItem("cntMatches") > "0") {
        setIsMsgDisplayed(true);
      }
      localStorage.setItem("arriveFromPopup", 0);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);
  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/doubleMatchDetails",
        config
      );

      const userData = response.data;
      if (userData[0].length > 0) {
        setUserName(userData[0][0].userName);
        setDetails1(userData[0][0].details);
        setfreeText1(userData[0][0].freeText);
        if (userData[0][0].keyImage1 !== "") {
          const image1Data = await FileManager.getFile(
            userData[0][0].keyImage1
          );
          setImage1a(image1Data.file);
          setImage1aBlobURL(image1Data.blobURL);
        }
        setReligous1(userData[0][0].religous);
        setTall1(userData[0][0].tall);
        setEducation1(userData[0][0].education);
        setStatus1(userData[0][0].status);
        setOccupation1(userData[0][0].occupation);
        setOrigin1(userData[0][0].origin);
        setHobbies1(userData[0][0].hobbies);
        setHobbieFree1(userData[0][0].hobbieFree);
        if (userData[0][0].vip == 0) {
          setVipMatch1("0");
          setIsDataLoaded(true);
        } else {
          setVipMatch1("1");
          setOtherVip1(userData[0][0].otherVip);
          setOtherName1(userData[0][0].otherName);

          setCellFriend1(userData[0][0].cellFriend);

          if (userData[0][0].keyImage2 !== "") {
            const image2Data = await FileManager.getFile(
              userData[0][0].keyImage2
            );
            setImage2a(image2Data.file);
            setImage2aBlobURL(image2Data.blobURL);
          }
          if (userData[0][0].keyImage3 !== "") {
            const image3Data = await FileManager.getFile(
              userData[0][0].keyImage3
            );
            setImage3a(image3Data.file);
            setImage3aBlobURL(image3Data.blobURL);
          }
          if (userData[0][0].keyImage4 !== "") {
            const image4Data = await FileManager.getFile(
              userData[0][0].keyImage4
            );
            setImage4a(image4Data.file);
            setImage4aBlobURL(image4Data.blobURL);
          }
          if (userData[0][0].keyImage5 !== "") {
            const image5Data = await FileManager.getFile(
              userData[0][0].keyImage5
            );
            setImage5a(image5Data.file);
            setImage5aBlobURL(image5Data.blobURL);
          }

          setFacebook1(userData[0][0].facebook);
          setInstegram1(userData[0][0].instegram);
          setLinkedin1(userData[0][0].linkedin);
          setIdMatch1(userData[0][0].MatchId);
          setCellular1(userData[0][0].cellular);

          setChoice1(userData[0][0].myResponse);
          setChoice1Saved(userData[0][0].myResponse > 0 ? true : false);
          setOtherResponse1(userData[0][0].otherResponse);
          setIsEmailSent1(userData[0][0].isEmailSent);
          setOtherHadSee1(userData[0][0].otherHadSee);
          setFirstName1(userData[0][0].firstName);
          setMsgISent1(userData[0][0].msgISent);
          setMsgIGot1(userData[0][0].msgIGot);
          setRenewDate1(userData[0][0].renewDate);
          setAlmost1(userData[0][0].almost);

          if (userData[0][0].rec1 !== "") {
            const rec1aBuffer = userData[0][0].rec1
              .split(",,")
              .map(
                (current) =>
                  new Int8Array(current.split(",").map((current) => +current))
              );
            const blob1a = new Blob(rec1aBuffer, {
              type: "audio/mp3",
            });
            const blob1aURL = URL.createObjectURL(blob1a);

            setRecordingBlobURL1a(blob1aURL);
          }
          // setRecordingBlob(blob1a);

          setMyRecord1a(userData[0][0].myRec1);
          setIsDataLoaded(true);
        }
      } else {
        setIdMatch1("0");
        setChoice1("0");
        setIsDataLoaded(true); // in case there is no data
      }

      if (userData[0].length > 1) {
        setDetails2(userData[0][1].details);
        setfreeText2(userData[0][1].freeText);
        if (userData[0][1].keyImage1 !== "") {
          const image1Data = await FileManager.getFile(
            userData[0][1].keyImage1
          );
          setImage1b(image1Data.file);
          setImage1bBlobURL(image1Data.blobURL);
        }
        setReligous2(userData[0][1].religous);
        setTall2(userData[0][1].tall);
        setEducation2(userData[0][1].education);
        setStatus2(userData[0][1].status);
        setOccupation2(userData[0][1].occupation);
        setOrigin2(userData[0][1].origin);
        setHobbies2(userData[0][1].hobbies);
        setHobbieFree2(userData[0][1].hobbieFree);

        if (userData[0][1].vip == 0) {
          setVipMatch2("0");
          setIsDataLoaded(true);
        } else {
          setVipMatch2("1");
          setOtherVip2(userData[0][1].otherVip);
          setOtherName2(userData[0][1].otherName);

          setCellFriend2(userData[0][1].cellFriend);

          if (userData[0][1].keyImage2 !== "") {
            const image2Data = await FileManager.getFile(
              userData[0][1].keyImage2
            );
            setImage2b(image2Data.file);
            setImage2bBlobURL(image2Data.blobURL);
          }
          if (userData[0][1].keyImage3 !== "") {
            const image3Data = await FileManager.getFile(
              userData[0][1].keyImage3
            );
            setImage3b(image3Data.file);
            setImage3bBlobURL(image3Data.blobURL);
          }
          if (userData[0][1].keyImage4 !== "") {
            const image4Data = await FileManager.getFile(
              userData[0][1].keyImage4
            );
            setImage4b(image4Data.file);
            setImage4bBlobURL(image4Data.blobURL);
          }
          if (userData[0][1].keyImage5 !== "") {
            const image5Data = await FileManager.getFile(
              userData[0][1].keyImage5
            );
            setImage5b(image5Data.file);
            setImage5bBlobURL(image5Data.blobURL);
          }

          setFacebook2(userData[0][1].facebook);
          setInstegram2(userData[0][1].instegram);
          setLinkedin2(userData[0][1].linkedin);
          setIdMatch2(userData[0][1].MatchId);
          setCellular2(userData[0][1].cellular);

          setChoice2(userData[0][1].myResponse);
          setChoice2Saved(userData[0][1].myResponse > 0 ? true : false);
          setOtherResponse2(userData[0][1].otherResponse);
          setIsEmailSent2(userData[0][1].isEmailSent);
          setOtherHadSee2(userData[0][1].otherHadSee);
          setFirstName2(userData[0][1].firstName);
          setMsgISent2(userData[0][1].msgISent);
          setMsgIGot2(userData[0][1].msgIGot);
          setRenewDate2(userData[0][1].renewDate);
          setAlmost2(userData[0][1].almost);

          if (userData[0][1].rec1 !== "") {
            const rec2aBuffer = userData[0][1].rec1
              .split(",,")
              .map(
                (current) =>
                  new Int8Array(current.split(",").map((current) => +current))
              );
            const blob2a = new Blob(rec2aBuffer, {
              type: "audio/mp3",
            });
            const blob2aURL = URL.createObjectURL(blob2a);

            setRecordingBlobURL2a(blob2aURL);
          }
          // setRecordingBlob(blob1a);

          setMyRecord2a(userData[0][1].myRec1);

          setIsDataLoaded(true);
        }
      } else {
        setIdMatch2("0");
        setChoice2("0");
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  // useEffect(async () => {
  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_LOCALHOST_ADDRESS + "/oldDoubleYes",
  //       config
  //     );
  //     const userData = response.data;
  //     if (userData[0].length > 0) {
  //       setDoubleYes1(userData[0][0].doubleYes);
  //     }
  //     if (userData[0].length > 1) {
  //       setDoubleYes2(userData[0][1].doubleYes);
  //     }
  //     if (userData[0].length > 2) {
  //       setDoubleYes3(userData[0][2].doubleYes);
  //     }
  //   } catch (error) {
  //     // probebly user is not authenticated or server is down
  //   }
  // }, []);

  const onSubmit = async (idMatch, choice, msgForOtherSide) => {
    // if ((choice1 != "" && idMatch1 > 0) || idMatch1 === "0") {
    if (choice !== "" && choice > 0) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/matchesResponse",
          {
            idMatch,
            choice,
            msgForOtherSide,
          },
          config
        );
        if (response.data === true) {
          alertUser("נשמר בהצלחה");
          window.location.reload(false);
        } else {
          alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
        }
      } catch (error) {
        alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } else {
      alertUser("עליך לסמן את הבחירה שלך");
    }
  };

  return (
    <form className="double-match-form">
      <HeaderBack />
      {isDataLoaded === true ? (
        <>
          <br></br>
          <div className="doublematch-body">
            <div id="h1">דאבל התאמות</div>
            <h5 style={{ textAlign: "center" }}>
              {userName.length > 1
                ? " הי " +
                  userName +
                  " " +
                  getWordByGender("dear") +
                  ", קיבלת התאמה מקווים ש" +
                  getWordByGender("connect") +
                  " :)"
                : ""}
            </h5>
            {getHared() == "1" && (cellular1 != "" || cellular2 != "") ? (
              <div className="messegeMatch">
                רוצה ששדכן מאיתנו יצור קשר עם ההורים ויציע את ההצעה הזו? אפשר
                אצל: <br />
                <div className="cellularline">
                  חיים שלר
                  <a
                    href={"https://wa.me/972549762276"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="whatsuplink" />
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <div className="messegeMatch">{messege}</div> */}

            <Popup
              open={isMsgDisplayed}
              contentStyle={{
                maxWidth: "45%",
                borderRadius: "5px",
                direction: "rtl",
                textAlign: "center",
              }}
              position="right center"
              onClose={() =>
                // ;
                //  window.location.reload(false)
                setIsMsgDisplayed(false)
              }
              closeOnDocumentClick
              closeOnEscape
            >
              {(choice1 == 3 && localStorage.getItem("lastMatchChosen") == 1) ||
              (choice2 == 3 && localStorage.getItem("lastMatchChosen") == 2)
                ? "התשובה נשמרה! מרגישים שההצעות לא מתאימות? שווה לבדוק מה סימנתם שאתם מחפשים"
                : (choice1 == 2 &&
                    localStorage.getItem("lastMatchChosen") == 1) ||
                  (choice2 == 2 && localStorage.getItem("lastMatchChosen") == 2)
                ? " התשובה נשמרה! שימו לב, אם הצד השני לא יגיד לא, יתכן וההצעה תופיע שוב בעוד מספר שבועות"
                : (choice1 == 1 &&
                    localStorage.getItem("lastMatchChosen") == 1 &&
                    (otherHadSee1 != "1" || otherResponse1 == "0")) ||
                  (choice2 == 1 &&
                    localStorage.getItem("lastMatchChosen") == 2 &&
                    (otherHadSee2 != "1" || otherResponse2 == "0"))
                ? "התשובה נשמרה! אם הצד השני לא יראה בקרוב את ההתאמה אתם יכולים לבקש מאיתנו לשלוח תזכורת בווטסאפ"
                : messege}{" "}
              <div
                className={
                  cellular1 != "" || cellular2 != "" ? "lbl" : "invisible"
                }
              >
                {" "}
                <br />
                <div style={{ fontSize: "15px" }}>
                  <div>הנה לינק ישיר לווטאספ של</div>
                  {cellular1 != "" ? firstName1 : ""}{" "}
                  <div className="whatsuplinka">
                    {cellular1 != "" ? (
                      <a
                        href={
                          `https://wa.me/972` +
                          cellular1.replace("-", "") +
                          `?text=הי+${firstName1}+מה+נשמע+זה+${userName}+מדאבל+:)...`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="whatsuplink" />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {cellular2 != "" ? firstName2 : ""}{" "}
                  <div className="whatsuplinka">
                    {cellular2 != "" ? (
                      <a
                        href={
                          `https://wa.me/972` +
                          cellular2.replace("-", "") +
                          `?text=הי+${firstName2}+מה+נשמע+זה+${userName}+מדאבל+:)...`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="whatsuplink" />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div
                  className=""
                  // style={{ fontSize: "15px" }}
                >
                  <br />
                  רוצה לזכות בנקודות? זה פשוט, {getWordByGender(
                    "beTheFirst"
                  )}{" "}
                  לשלוח הודעה לצד השני, יש קישור לווטסאפ בפנים.
                  <br />
                  <br />
                  <div className="faceline">
                    <div className="toggle-line">
                      <label className="toggle">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIllswndwhatsupp(!illswndwhatsupp);
                          }}
                          checked={illswndwhatsupp}
                        />
                        <span className="slider-in-toggle"></span>
                      </label>

                      <div className="lbl">
                        {illswndwhatsupp ? "סבבה, עכשיו אשלח ווטסאפ" : " "}
                      </div>
                    </div>
                    <br />
                    <div
                      className={
                        illswndwhatsupp === true
                          ? "button-text-center narrow"
                          : "invisible"
                      }
                      onClick={() => {
                        onCollectStars("6");
                      }}
                    >
                      שמירה
                    </div>
                  </div>
                  {isErrorMessageDisplayed && (
                    <div className="error-message">
                      <h5>{errorMessage2} </h5>
                    </div>
                  )}
                </div> */}
              </div>
            </Popup>

            {/* <div className="infoicon" onClick={infoBtnClick}></div> */}
            {renewDate1 != "" ? (
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                ** זוהי הצעה חוזרת, כדאי לחשוב שוב, והפעם לסמן תשובה :){" "}
              </h6>
            ) : (
              ""
            )}
            {almost1 != "" && almost1 != "0" && almost1 != "6" ? (
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                ** הי {userName}, קיבלת הצעה כמעט מתאימה - היא עונה על הכל חוץ
                מאשר {getAlmostByVal(almost1)}.
                <br />
                ניתן לערוך מה יכול להיות ”כמעט מתאים“
                <Link
                  to="/Almost"
                  // style={{ textDecoration: "none" }}
                >
                  {" כאן "}
                </Link>
              </h6>
            ) : almost1 == "6" ? (
              <h5
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                הי! השבוע בוצעו התאמות מיוחדות, {getAlmostByVal(almost1)}.
                <br />
                אולי זה יהיה המזל שלך? הכל יכול להיות!
                <br />
              </h5>
            ) : (
              <></>
            )}
            <div
              className="button-text-center"
              id="btnMatch"
              onClick={infoBtnClick}
            >
              {details1}
              {/* <div className={otherVip1 == "1" ? "vip" : ""}></div> */}
            </div>

            <div id="match1">
              <div>
                {" "}
                {/* {startTimer1 && choice1 == "" ? (
                    <TimerResponse timerDisplay={true} maxRange={60} />
                  ) : (
                    <></>
                  )} */}
                <h5 className="vipDescription">
                  {getVip() === "1" && otherVip1 == "1"
                    ? " שניכם מנויים לאתר וניתן לראות פרטים מלאים :)"
                    : getVip() === "1"
                    ? " יש לך מנוי לאתר ולכן ניתן לראות פרטים מלאים!"
                    : otherVip1 == "1"
                    ? otherName1 +
                      " " +
                      getWordByGender("otherVip") +
                      " לאתר וניתן לראות פרטים מלאים!"
                    : ""}
                </h5>
                <div className="cellularblock">
                  <h5>
                    {cellular1 != ""
                      ? "הי שניכם סימנתם שתשמחו להכיר! "
                      : " כאן מוצג הטלפון אם שניכם מסמנים שמתאים"}
                  </h5>
                  <div className="cellularline">
                    <div
                      className={cellular1 != "" ? " " : "cellularicon"}
                    ></div>

                    <div className="messegeMatch">
                      {cellular1 != ""
                        ? cellular1 +
                          "  בהצלחה :)  " +
                          "\n" +
                          userName +
                          " " +
                          getWordByGender("beTheFirst") +
                          " לשלוח הודעה ל" +
                          firstName1
                        : // + getWordByGender("maybe")

                          ""}
                    </div>
                    <div className="whatsuplinka">
                      {cellular1 != "" ? (
                        <a
                          href={
                            "https://wa.me/972" + cellular1.replace("-", "")
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="whatsuplink" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={cellular1 != "" ? "lbl" : "invisible"}>
                    <Link to="/findplace">{"  נמצא מקום חדש לדייט? "}</Link>
                  </div>
                </div>
                <div className="faceline">
                  {image1a !== "" ? (
                    <>
                      {location.pathname.includes("/image1a") ? (
                        <img
                          className="image-fullscreen"
                          src={image1aBlobURL}
                          alt=""
                          id="img1a"
                        />
                      ) : (
                        <img
                          className="image-doubleMatch"
                          src={image1aBlobURL}
                          alt=""
                          id="img1a"
                        />
                      )}
                    </>
                  ) : null}
                  <div>
                    {vipMatch1 == "1" ? (
                      <div>
                        <div className="messegeMatch">
                          {otherHadSee1 == "1" && otherResponse1 == ""
                            ? "( הצד השני ראה את ההצעה וטרם הגיב)"
                            : otherHadSee1 == "1"
                            ? "(הצד השני ראה את ההצעה)"
                            : "(הצד השני טרם ראה את ההצעה)"}
                        </div>
                        <div className="messegeMatch">
                          {" "}
                          <b>תשובת הצד השני</b>
                          {": "}
                          {choice1 != "" && choice1Saved
                            ? getOtherChoice(otherResponse1)
                            : "תוצג לאחר בחירתך"}
                        </div>
                      </div>
                    ) : null}

                    <h5>מי אני</h5>
                    <div className="messegeMatch">
                      {getWordByGender("pdat" + religous1)} , {tall1} ס'מ ,{" "}
                      {getWordByGender("psta" + status1)}
                    </div>
                    <div className="messegeMatch">
                      השכלה:
                      {getWordByGender("pedu" + education1)}
                    </div>
                    <div className="messegeMatch">
                      עיסוק:
                      {occupation1}
                    </div>
                    <div className="messegeMatch">
                      מוצא:
                      {origin1}
                    </div>
                    <h5> התחביבים שלי</h5>
                    <div className="messegeMatch">
                      {hobbies1
                        .split(",")
                        .map((current) => getHobby(current))
                        .join(", ")}
                      {hobbieFree1 != null ? ", " : ""}
                      {hobbieFree1}
                    </div>
                  </div>
                  <div></div>
                </div>
                <h5> טקסט חופשי</h5>
                {vipMatch1 == "0" ? (
                  "הטקסט מופיע כאשר אחד מהצדדים מנוי"
                ) : (
                  <div className="messegeMatch"> {freeText1}</div>
                )}
                {vipMatch1 == "1" ? (
                  <div>
                    <h5> טלפון לבירורים </h5>
                    <div className="messegeMatch"> {cellFriend1}</div>
                  </div>
                ) : null}
                <br />
                <h5> תמונות ועוד</h5>
                {vipMatch1 == "0" ? (
                  <div>
                    אופס... פרטים מלאים מופיעים אם אחד מהצדדים מנוי.
                    <div className="lbl_grey">
                      <Link
                        to="/Subscribe"
                        // style={{ textDecoration: "none" }}
                      >
                        {" לרכישת מנוי "}
                      </Link>
                      במחיר של כוס קפה חודשית
                    </div>
                    <div
                      className="lbl_grey"
                      onClick={() => setVipExplain(true)}
                    >
                      <u> למנויים יש יותר דייטים.</u>
                      (לא כל המנויים הם בתשלום)
                    </div>
                    <Popup
                      open={vipExplain}
                      contentStyle={{
                        minWidth: "30%",
                        maxWidth: "40%",
                        borderRadius: "5px",
                      }}
                      position="top center"
                      onClose={() => setVipExplain(false)}
                      closeOnDocumentClick
                      closeOnEscape
                    >
                      <div
                        className="exit-popup"
                        onClick={() => {
                          setVipExplain(false);
                        }}
                      >
                        x
                      </div>
                      <br />
                      <DateCount />
                      <br />
                    </Popup>
                  </div>
                ) : (
                  <div>
                    <div className="iconsline">
                      <div
                        className={
                          image1a != "" ? "cameraicon" : "cameraicongrey"
                        }
                        onClick={cameraBtnClick}
                      ></div>

                      <a
                        href={facebook1 != "" ? facebook1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            facebook1 != "" ? "faceicon" : "faceicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            facebook1 != "" ? facebookBtnClick : () => {}
                          }
                        ></div>
                      </a>

                      {/* <div
                        className={
                          facebook1 != "" ? "faceicon" : "faceicongrey"
                        }
                        onClick={facebook1 != "" ? facebookBtnClick : () => {}}
                      ></div> */}
                      <a
                        href={instegram1 != "" ? instegram1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            instegram1 != ""
                              ? "instegramicon"
                              : "instegramicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            instegram1 != "" ? instegramBtnClick : () => {}
                          }
                        ></div>
                      </a>
                      <a
                        href={linkedin1 != "" ? linkedin1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            linkedin1 != ""
                              ? "linkedinicon"
                              : "linkedinicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            linkedin1 != "" ? linkedinBtnClick : () => {}
                          }
                        ></div>
                      </a>

                      <div
                        // className="recordicon"
                        // onClick={recordBtnClick}

                        className={
                          recordingBlobURL1a !== ""
                            ? "recordicon"
                            : "recordicongrey"
                        }
                        onClick={
                          recordingBlobURL1a !== "" ? recordBtnClick : () => {}
                        }
                      ></div>
                    </div>
                    <div id="image1">
                      {image1a !== "" ? (
                        <>
                          {location.pathname.includes("/image1a") ? (
                            <img
                              className="image-fullscreen"
                              src={image1aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1a")}
                              id="img1a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image1aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1a")}
                              id="img1a"
                            />
                          )}
                        </>
                      ) : null}
                      {image2a !== "" ? (
                        <>
                          {location.pathname.includes("/image2a") ? (
                            <img
                              className="image-fullscreen"
                              src={image2aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2a")}
                              id="img2a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image2aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2a")}
                              id="img2a"
                            />
                          )}
                        </>
                      ) : null}
                      {image3a !== "" ? (
                        <>
                          {location.pathname.includes("/image3a") ? (
                            <img
                              className="image-fullscreen"
                              src={image3aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3a")}
                              id="img3a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image3aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3a")}
                              id="img3a"
                            />
                          )}
                        </>
                      ) : null}
                      {image4a !== "" ? (
                        <>
                          {location.pathname.includes("/image4a") ? (
                            <img
                              className="image-fullscreen"
                              src={image4aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4a")}
                              id="img4a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image4aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4a")}
                              id="img4a"
                            />
                          )}
                        </>
                      ) : null}
                      {image5a !== "" ? (
                        <>
                          {location.pathname.includes("/image5a") ? (
                            <img
                              className="image-fullscreen"
                              src={image5aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5a")}
                              id="img5a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image5aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5a")}
                              id="img5a"
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                    <div id="facebook1">
                      {"הקישור לפייס הוא: "}
                      <a
                        href={facebook1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {facebook1}
                      </a>
                    </div>
                    <div id="instegram1">
                      {"הקישור לאינסטגרם הוא: "}
                      <a
                        href={instegram1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {instegram1}
                      </a>
                    </div>
                    <div id="linkedin1">
                      {"הקישור ללינקדין הוא: "}
                      <a
                        href={linkedin1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linkedin1}
                      </a>
                    </div>
                    <div id="record1">
                      <div className="record-line">
                        <ReactPlayer
                          playing={isRecord1aPlaying}
                          autoPlay={false}
                          url={recordingBlobURL1a}
                          controls={true}
                          progressInterval={100}
                          config={{
                            file: {
                              forceAudio: true,
                            },
                          }}
                          onEnded={() => {
                            setIsRecord1aPlaying(false);
                          }}
                          width="0px"
                          height="0px"
                        />
                        <div Style="width: 33.3%">
                          <div
                            className={
                              "playicon-button " +
                              (isRecord1aPlaying ? "pause-icon" : "play-icon")
                            }
                            onClick={() => {
                              setIsRecord1aPlaying(!isRecord1aPlaying);
                            }}
                          ></div>
                          <p className="lbl">לשמיעה</p>
                        </div>
                        {myRecord1a == 1 ? "אני על עצמי" : "מספרים עלי"}
                        {record1a + dateRecord1a}
                      </div>
                    </div>
                    <br />

                    <div>
                      {msgIGot1.length > 1 ? (
                        <h5> קיבלת הודעה מ{firstName1}:</h5>
                      ) : (
                        ""
                      )}
                      <div className="messegeMatch">{msgIGot1}</div>
                    </div>
                    <div
                      className={
                        choice1 != "" && choice1Saved
                          ? "messegeMatch"
                          : "invisible"
                      }
                    >
                      <b>תשובת הצד השני: </b>
                      {getOtherChoice(otherResponse1)}
                    </div>
                  </div>
                )}
                <div className="faceline">
                  <div className="parent-choice">
                    <input
                      type="button"
                      id="good"
                      name="choice"
                      value="אשמח להכיר :)"
                      className={`choiceCube ${
                        choice1 === 1
                          ? "active"
                          : vipMatch1 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice1(1)}
                    />
                    <input
                      type="button"
                      id="maybe"
                      name="choice"
                      value={"סורי, לא פנוי" + (getGender() === "1" ? "ה" : "")}
                      className={`choiceCube ${
                        choice1 === 2
                          ? "active"
                          : vipMatch1 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice1(2)}
                    />
                    <input
                      type="button"
                      id="notgood"
                      name="choice"
                      value="תודה, פחות מתאים"
                      className={`choiceCube ${
                        choice1 === 3
                          ? "active"
                          : vipMatch1 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice1(3)}
                    />
                  </div>
                  {vipMatch1 == "1" ? (
                    <div>
                      <h5>אפשר להשאיר הודעה לצד השני :)</h5>
                      <div className="section-title" Style={"font-size:11px"}>
                        {msgForOtherSideLength1 + "/100 "}{" "}
                      </div>
                      <div className="freetext-container">
                        <textarea
                          Style={"height:70px"}
                          className="freetext"
                          rows="3"
                          maxLength="100"
                          value={msgForOtherSide1}
                          onChange={(e) => handleText1(e.target.value)}
                        ></textarea>
                      </div>
                      <h5 Style={"font-size:11px; margin-top: -5px"}>
                        ההודעה שהשארת ל{firstName1}:{" "}
                      </h5>
                      <div className="messegeMatch" Style={"font-size:11px"}>
                        {msgISent1}{" "}
                      </div>
                    </div>
                  ) : null}
                </div>
                {isErrorMessageDisplayed && (
                  <div className="error-message">
                    <h5>{errorMessageFirstChoice} </h5>
                  </div>
                )}
                <div className="rectangle animationDown"></div>
                <div className="triangle-down animationDown"></div>
                {vipMatch1 == "1" ? (
                  <div
                    className="button-text-center"
                    id="btnSubmit"
                    onClick={() => {
                      localStorage.setItem("lastMatchChosen", 1);
                      onSubmit(idMatch1, choice1, msgForOtherSide1);
                    }}
                  >
                    שמירת התשובה וההודעה
                  </div>
                ) : null}
              </div>
            </div>

            {/* <div className="line" /> */}

            {/* <div className="infoicon" onClick={infoBtnClick2}></div> */}
            {renewDate2 != "" ? (
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                ** זוהי הצעה חוזרת, כדאי לחשוב שוב, והפעם לסמן תשובה :){" "}
              </h6>
            ) : (
              ""
            )}

            {almost2 != "" && almost2 != "0" && almost1 != "6" ? (
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                ** הי {userName}, קיבלת הצעה כמעט מתאימה - היא עונה על הכל חוץ
                מאשר {getAlmostByVal(almost2)}.
                <br />
                ניתן לערוך מה יכול להיות ”כמעט מתאים“
                <Link
                  to="/Almost"
                  // style={{ textDecoration: "none" }}
                >
                  {" כאן "}
                </Link>
              </h6>
            ) : (
              ""
            )}

            <div
              className={details2 != "" ? "button-text-center" : "invisible"}
              id="btnMatch"
              onClick={infoBtnClick2}
            >
              {details2}
            </div>

            <div id="match2">
              <div>
                {/* {startTimer2 && choice2 == "" ? (
                    <TimerResponse timerDisplay={true} maxRange={60} />
                  ) : (
                    <></>
                  )} */}
                <h5 className="vipDescription">
                  {getVip() === "1" && otherVip2 == "1"
                    ? " שניכם מנויים לאתר וניתן לראות פרטים מלאים! :)"
                    : getVip() === "1"
                    ? " יש לך מנוי לאתר ולכן ניתן לראות פרטים מלאים!"
                    : otherVip2 == "1"
                    ? otherName2 +
                      " " +
                      getWordByGender("otherVip") +
                      " לאתר וניתן לראות פרטים מלאים! "
                    : ""}
                </h5>
                <div className="cellularblock">
                  <h5>
                    {cellular2 != ""
                      ? "הי שניכם סימנתם שתשמחו להכיר! "
                      : " כאן מוצג הטלפון אם שניכם מסמנים שמתאים"}
                  </h5>
                  <div className="cellularline">
                    <div
                      className={cellular2 != "" ? " " : "cellularicon"}
                    ></div>
                    <div className="messegeMatch">
                      {cellular2 != ""
                        ? "       " +
                          cellular2 +
                          "  בהצלחה :)  " +
                          "\n" +
                          userName +
                          " " +
                          getWordByGender("beTheFirst") +
                          " לשלוח הודעה ל" +
                          firstName2
                        : // + getWordByGender("maybe") +
                          // "\n" +
                          // "(אגב, גם נשלח אליכם מייל)" +
                          // "\n"
                          ""}
                    </div>
                    <div className="whatsuplinka">
                      {cellular2 != "" ? (
                        <a
                          href={
                            "https://wa.me/972" + cellular2.replace("-", "")
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="whatsuplink" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={cellular2 != "" ? "lbl" : "invisible"}>
                    <Link to="/findplace">{"  נמצא מקום חדש לדייט? "}</Link>
                  </div>
                </div>
                <div className="faceline">
                  {image1b !== "" ? (
                    <>
                      {location.pathname.includes("/image1b") ? (
                        <img
                          className="image-fullscreen"
                          src={image1bBlobURL}
                          alt=""
                          id="img1b"
                        />
                      ) : (
                        <img
                          className="image-doubleMatch"
                          src={image1bBlobURL}
                          alt=""
                          id="img1b"
                        />
                      )}
                    </>
                  ) : null}
                  <div>
                    {vipMatch2 == "1" ? (
                      <div>
                        <div className="messegeMatch">
                          {otherHadSee2 == "1" && otherResponse2 == ""
                            ? "( הצד השני ראה את ההצעה וטרם הגיב)"
                            : otherHadSee2 == "1"
                            ? "(הצד השני ראה את ההצעה)"
                            : "(הצד השני טרם ראה את ההצעה)"}
                        </div>
                        <div className="messegeMatch">
                          {" "}
                          <b>תשובת הצד השני</b>
                          {": "}
                          {choice2 != "" && choice2Saved
                            ? getOtherChoice(otherResponse2)
                            : "תוצג לאחר בחירתך"}
                        </div>
                      </div>
                    ) : null}
                    <h5>מי אני</h5>
                    <div className="messegeMatch">
                      {getWordByGender("pdat" + religous2)} , {tall2} ס'מ ,{" "}
                      {getWordByGender("psta" + status2)}
                    </div>
                    <div className="messegeMatch">
                      השכלה:
                      {getWordByGender("pedu" + education2)}
                    </div>
                    <div className="messegeMatch">
                      עיסוק:
                      {occupation2}
                    </div>
                    <div className="messegeMatch">
                      מוצא:
                      {origin2}
                    </div>
                    <h5> התחביבים שלי</h5>
                    <div className="messegeMatch">
                      {hobbies2
                        .split(",")
                        .map((current) => getHobby(current))
                        .join(", ")}
                      {hobbieFree2 != null ? ", " : ""}
                      {hobbieFree2}
                    </div>
                  </div>
                </div>

                <h5> טקסט חופשי</h5>
                {vipMatch2 == "0" ? (
                  "הטקסט מופיע כאשר אחד מהצדדים מנוי"
                ) : (
                  <div className="messegeMatch">{freeText2}</div>
                )}
                {vipMatch2 == "1" ? (
                  <div>
                    <h5> טלפון לבירורים </h5>
                    <div className="messegeMatch"> {cellFriend2}</div>
                  </div>
                ) : null}
                <br />
                <h5> תמונות ועוד</h5>
                {vipMatch2 == "0" ? (
                  <div>
                    אופס... פרטים מלאים מופיעים אם אחד מהצדדים מנוי.
                    <div className="lbl_grey">
                      <Link
                        to="/Subscribe"
                        // style={{ textDecoration: "none" }}
                      >
                        {" לרכישת מנוי "}
                      </Link>
                      במחיר של כוס קפה חודשית
                    </div>{" "}
                    <div
                      className="lbl_grey"
                      onClick={() => setVipExplain(true)}
                    >
                      <u> למנויים יש יותר דייטים.</u>( לא כל המנויים הם בתשלום)
                    </div>
                    <Popup
                      open={vipExplain}
                      contentStyle={{
                        minWidth: "30%",
                        maxWidth: "40%",
                        borderRadius: "5px",
                      }}
                      position="top center"
                      onClose={() => setVipExplain(false)}
                      closeOnDocumentClick
                      closeOnEscape
                    >
                      <div
                        className="exit-popup"
                        onClick={() => {
                          setVipExplain(false);
                        }}
                      >
                        x
                      </div>
                      <br />
                      <DateCount />
                      <br />
                    </Popup>
                  </div>
                ) : (
                  <div>
                    <div className="iconsline">
                      <div
                        className={
                          image1b != "" ? "cameraicon" : "cameraicongrey"
                        }
                        onClick={cameraBtnClick2}
                      ></div>

                      <a
                        href={facebook2 != "" ? facebook2 : null}
                        target="_blank"
                      >
                        <div
                          className={
                            facebook2 != "" ? "faceicon" : "faceicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            facebook2 != "" ? facebookBtnClick2 : () => {}
                          }
                        ></div>
                      </a>
                      <a
                        href={instegram2 != "" ? instegram2 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            instegram2 != ""
                              ? "instegramicon"
                              : "instegramicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            instegram2 != "" ? instegramBtnClick2 : () => {}
                          }
                        ></div>
                      </a>
                      <a
                        href={linkedin2 != "" ? linkedin2 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            linkedin2 != ""
                              ? "linkedinicon"
                              : "linkedinicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            linkedin2 != "" ? linkedinBtnClick2 : () => {}
                          }
                        ></div>
                      </a>

                      <div
                        className={
                          recordingBlobURL2a !== ""
                            ? "recordicon"
                            : "recordicongrey"
                        }
                        onClick={
                          recordingBlobURL2a !== "" ? recordBtnClick2 : () => {}
                        }
                      ></div>
                    </div>
                    <div id="image2">
                      {image1b !== "" ? (
                        <>
                          {location.pathname.includes("/image1b") ? (
                            <img
                              className="image-fullscreen"
                              src={image1bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1b")}
                              id="img1b"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image1bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1b")}
                              id="img1b"
                            />
                          )}
                        </>
                      ) : null}
                      {image2b !== "" ? (
                        <>
                          {location.pathname.includes("/image2b") ? (
                            <img
                              className="image-fullscreen"
                              src={image2bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2b")}
                              id="img2b"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image2bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2b")}
                              id="img2b"
                            />
                          )}
                        </>
                      ) : null}
                      {image3b !== "" ? (
                        <>
                          {location.pathname.includes("/image3b") ? (
                            <img
                              className="image-fullscreen"
                              src={image3bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3b")}
                              id="img3b"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image3bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3b")}
                              id="img3b"
                            />
                          )}
                        </>
                      ) : null}
                      {image4b !== "" ? (
                        <>
                          {location.pathname.includes("/image4b") ? (
                            <img
                              className="image-fullscreen"
                              src={image4bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4b")}
                              id="img4b"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image4bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4b")}
                              id="img4b"
                            />
                          )}
                        </>
                      ) : null}
                      {image5b !== "" ? (
                        <>
                          {location.pathname.includes("/image5b") ? (
                            <img
                              className="image-fullscreen"
                              src={image5bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5b")}
                              id="img5b"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image5bBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5b")}
                              id="img5b"
                            />
                          )}
                        </>
                      ) : null}
                    </div>

                    <div id="facebook2">
                      {"הקישור לפייס הוא: "}
                      <a
                        href={facebook2}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {facebook2}
                      </a>
                    </div>
                    <div id="instegram2">
                      {"הקישור לאינסטגרם הוא: "}
                      <a
                        href={instegram2}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {instegram2}
                      </a>
                    </div>
                    <div id="linkedin2">
                      {"הקישור ללינקדין הוא: "}
                      <a
                        href={linkedin2}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linkedin2}
                      </a>
                    </div>
                    <div id="record2">
                      <div className="record-line">
                        <ReactPlayer
                          playing={isRecord2aPlaying}
                          autoPlay={false}
                          url={recordingBlobURL2a}
                          controls={true}
                          progressInterval={100}
                          config={{
                            file: {
                              forceAudio: true,
                            },
                          }}
                          onEnded={() => {
                            setIsRecord2aPlaying(false);
                          }}
                          width="0px"
                          height="0px"
                        />
                        <div Style="width: 33.3%">
                          <div
                            className={
                              "playicon-button " +
                              (isRecord2aPlaying ? "pause-icon" : "play-icon")
                            }
                            onClick={() => {
                              setIsRecord2aPlaying(!isRecord2aPlaying);
                            }}
                          ></div>
                          <p className="lbl">לשמיעה</p>
                        </div>
                        {myRecord2a == 1 ? "אני על עצמי" : "מספרים עלי"}
                        {record2a + dateRecord2a}
                      </div>
                      {/* <div className="record-line">
                  <div className="playicon"></div>
                  <div className="stopplayicon"></div>
                  {myRecord2b == 1 ? "אני על עצמי" : "מספרים עלי"}
                  {record2b + dateRecord2b}
                </div> */}
                    </div>
                    <br />

                    <div>
                      {msgIGot2.length > 1 ? (
                        <h5> קיבלת הודעה מ{firstName2}:</h5>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="messegeMatch">{msgIGot2}</div>
                    <div
                      className={
                        choice2 != "" && choice2Saved
                          ? "messegeMatch"
                          : "invisible"
                      }
                    >
                      <b>תשובת הצד השני: </b>
                      {getOtherChoice(otherResponse2)}
                    </div>
                  </div>
                )}
                <div className="faceline">
                  <div className="parent-choice">
                    <input
                      type="button"
                      id="good2"
                      name="choice2"
                      value="אשמח להכיר :)"
                      className={`choiceCube ${
                        choice2 === 1
                          ? "active"
                          : vipMatch2 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice2(1)}
                    />
                    <input
                      type="button"
                      id="maybe2"
                      name="choice2"
                      value={"סורי, לא פנוי" + (getGender() === "1" ? "ה" : "")}
                      className={`choiceCube ${
                        choice2 === 2
                          ? "active"
                          : vipMatch2 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice2(2)}
                    />
                    <input
                      type="button"
                      id="notgood2"
                      name="choice2"
                      value="תודה, פחות מתאים"
                      className={`choiceCube ${
                        choice2 === 3
                          ? "active"
                          : vipMatch2 == "0"
                          ? "greyChoiceCube"
                          : ""
                      }`}
                      onClick={(e) => handleChoice2(3)}
                    />
                  </div>
                  {vipMatch2 == "1" ? (
                    <div>
                      <h5>אפשר להשאיר הודעה לצד השני :)</h5>
                      <div className="section-title" Style={"font-size:11px"}>
                        {msgForOtherSideLength2 + "/100 "}{" "}
                      </div>
                      <div className="freetext-container">
                        <textarea
                          Style={"height:70px"}
                          className="freetext"
                          rows="3"
                          maxLength="100"
                          value={msgForOtherSide2}
                          onChange={(e) => handleText2(e.target.value)}
                        ></textarea>
                      </div>

                      <h5 Style={"font-size:11px; margin-top: -5px"}>
                        ההודעה שהשארת ל{firstName2}:{" "}
                      </h5>
                      <div className="messegeMatch" Style={"font-size:11px"}>
                        {msgISent2}{" "}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="rectangle animationDown"></div>
                <div className="triangle-down animationDown"></div>
                {vipMatch2 == "1" ? (
                  <div
                    className="button-text-center"
                    id="btnSubmit"
                    onClick={() => {
                      localStorage.setItem("lastMatchChosen", 2);
                      onSubmit(idMatch2, choice2, msgForOtherSide2);
                    }}
                  >
                    שמירת התשובה וההודעה
                  </div>
                ) : null}
              </div>
            </div>

            {/* <div className="line" /> */}

            {/* <div className='line' />    */}
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}

            {/* <div className="button-text-center" id="btnSubmit" onClick={onSubmit}>
          אישור
        </div> */}
            <br />

            <a
              href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר ממש שווה, הוא שדכן וירטואלי.. זה הקישור: https://double2love.com"
              className="prev-matches-button"
              style={{ color: "var(--btn-color)" }}
            >
              ו.. אפשר ללחוץ כאן ולשתף את האתר לחבר.ה, לכולנו יהיו יותר התאמות
              :)
            </a>
            <div
              className="prev-matches-button"
              style={{ color: "var(--btn-color)" }}
            >
              <Link to="/findplace">
                {"  נמצא מקום חדש לצאת אליו? "}
                <div class="findplaceicon" />
              </Link>
            </div>

            <PrevMatches />
            <Link to="/Questionnaires" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>

            <br />
          </div>
          <Footer />
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default DoubleMatch;
